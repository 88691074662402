import React, { useEffect, createContext, useContext } from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import PubSub from "pubsub-js"
import Header from "./Header"
import Footer from "./Footer"
import { CategoriesWrapper } from "../contexts/CategoriesWrapper"
import { ShopWrapper } from "./shop/ShopContext"
import PageTransition from "./ui/PageTransition"
// import FaviconLetter from "./ui/FaviconLetter"
import Cursor from "./ui/Cursor"
import Splash from "./Splash"
// import { useScroll } from "../hooks/useScroll"

const WrapperContext = createContext({})

const Layout = ({ children, pageContext, location }) => {
  const { template, isHome } = pageContext
  // console.log(pageContext)
  const settings = {}

  // const { scrollY } = useScroll()
  // useEffect(() => {
  //   if (typeof window === "undefined") return

  //   if (isHome) {
  //     // console.log(scrollY)
  //     if (scrollY > window.innerHeight - 31) {
  //       document.body.classList.add("is-below-viewport")
  //     } else {
  //       document.body.classList.remove("is-below-viewport")
  //     }
  //   }
  // }, [scrollY])

  useEffect(() => {
    const _scroll = () => {
      // console.log(window.scrollY, isHome)
      if (isHome) {
        if (window.scrollY > window.innerHeight - 31) {
          document.body.classList.add("is-below-viewport")
        } else {
          document.body.classList.remove("is-below-viewport")
        }
      }
    }

    _format()
    _scroll()
    window.addEventListener("resize", _format)
    window.addEventListener("scroll", _scroll)
    return () => {
      window.removeEventListener("resize", _format)
      window.removeEventListener("scroll", _scroll)
    }
  }, [isHome])

  // const _scroll = () => {
  //   // console.log(window.scrollY, isHome)
  //   if (isHome) {
  //     if (window.scrollY > window.innerHeight - 31) {
  //       document.body.classList.add("is-below-viewport")
  //     } else {
  //       document.body.classList.remove("is-below-viewport")
  //     }
  //   }
  // }
  const _format = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    )

    const header = document.querySelector("header")
    header.classList.add("mix-blend-difference")

    const headerBounding = header.getBoundingClientRect()
    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    )

    const footerBounding = document
      .querySelector("footer")
      .getBoundingClientRect()

    document.documentElement.style.setProperty(
      "--footer-height",
      footerBounding.height + "px"
    )
  }

  return (
    <WrapperContext.Provider value={{ settings, location, template, isHome }}>
      <ShopWrapper>
        <div id="page">
          <div className="header-bg fixed top-0 w-full h-header-height mix-blend-difference z-10"></div>
          <Header />
          <PageTransition location={location}>
            <CategoriesWrapper>
              <main>{children}</main>
            </CategoriesWrapper>
          </PageTransition>
          <Footer />
        </div>
      </ShopWrapper>
      <Cursor size={15} color="white" />
      {isHome && <Splash />}
      {/* <FaviconLetter texte="B" background="#000" foreground="#fff" /> */}
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }
export default function useWrapperContext() {
  return useContext(WrapperContext)
}
