import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SanityTexte from "./SanityTexte"
import Clock from "./ui/Clock"
// import DesignSystem from "./ui/DesignSystem"

const query = graphql`
  query {
    sanityFooter {
      credits: _rawCredits(resolveReferences: { maxDepth: 10 })
      # nav {
      #   ... on SanityInternalLink {
      #     _type
      #     label
      #     link {
      #       ... on SanityPageModulaire {
      #         _type
      #         slug {
      #           current
      #         }
      #       }
      #     }
      #   }
      # }
    }
  }
`

const Footer = () => {
  const {
    sanityFooter: { credits },
  } = useStaticQuery(query)

  return (
    <footer className="px-sm md:px-md pt-[9px] pb-[8px] bg-secondary">
      <div className="flex flex-col md:flex-row md:justify-between md:items-end">
        <div className="mb-1e md:mb-0">
          <SanityTexte input={credits} />
        </div>
        {/* <nav>
          <ul className="flex">
            {nav.map((li, i) => (
              <li key={i} className="md:pl-sm">
                <Link to={_linkResolver(li.link)}>{li.label}</Link>
              </li>
            ))}
          </ul>
        </nav> */}
        <div className="clock">
          <Clock />
        </div>
      </div>
    </footer>
  )
}

export default Footer
