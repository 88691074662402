import { useState, useEffect } from "react"

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false)
  const [browser, setBrowser] = useState(false)

  useEffect(() => {
    _handleDetect()
    window.addEventListener("resize", _handleDetect)

    return () => window.removeEventListener("resize", _handleDetect)
  }, [setMobile])

  const _handleDetect = () => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent
    // setDevice(userAgent)
    const mobileUA = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
    const isSmallDevice = window.innerWidth <= 1080
    console.log(_getBrowser())
    setMobile(isSmallDevice ? true : mobileUA)

    setBrowser(_getBrowser())
  }

  const _getBrowser = () => {
    const test = regexp => regexp.test(window.navigator.userAgent)

    switch (true) {
      case test(/edg/i):
        return "browser-microsoft-edge"
      case test(/trident/i):
        return "Microsoft Internet Explorer"
      case test(/firefox|fxios/i):
        return "browser-mozilla-firefox"
      case test(/opr\//i):
        return "Opera"
      case test(/ucbrowser/i):
        return "UC Browser"
      case test(/samsungbrowser/i):
        return "Samsung Browser"
      case test(/chrome|chromium|crios/i):
        return "browser-google-chrome"
      case test(/safari/i):
        return "browser-apple-safari"
      default:
        return "Other"
    }
  }
  // console.log(isMobile)
  return { isMobile, browser }
}
