import React, { useState, useEffect } from "react"
// import { pad2 } from "../../core/utils"

const Clock = () => {
  // const [date, setDate] = useState("00.00")
  const [time, setTime] = useState("00.00")

  useEffect(() => {
    _update()
    const timer = setInterval(_update, 1000)
    // _start();
    return () => clearInterval(timer)
  }, [])

  const _update = () => {
    const now = new Date()
    // console.log(now.toLocaleTimeString("en-US"))
    // const day = pad2(now.getUTCDate())
    // const month = pad2(now.getUTCMonth() + 1)
    // const hour = pad2(now.getUTCHours())
    // const minute = pad2(now.getUTCMinutes())

    // setDate(`${day}.${month}`)
    // setTime(`${hour}:${minute}`)
    setTime(now.toLocaleTimeString("en-US"))
    // console.log(now);
  }

  return (
    <section className="clock ">
      {/* <span className="px-xxs">{date}</span>{" "} */}
      Current time {time} (UTC+1)
      {/* <span className="">{time}</span> */}
    </section>
  )
}

export default Clock
