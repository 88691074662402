import React from "react"
import { motion, AnimatePresence } from "framer-motion"

const duration = 0.35

const variants = {
  initial: {
    opacity: 0,
    y: 0,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: {
      duration: duration,
    },
  },
}

const PageTransition = ({ location, children }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
        // ref={scrollRef}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default PageTransition
