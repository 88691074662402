import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { subscribe, unsubscribe } from "pubsub-js"
import clsx from "clsx"
import { _linkResolver } from "../core/utils"
//import SearchForm from "./ui/SearchForm"
import Cart from "./shop/Cart"
import useWrapperContext from "./Layout"
import Burger from "./ui/Burger"
//import BtnSearchToggle from "./BtnSearchToggle"

import BtnNewsletterToggle from "./BtnNewsletterToggle"
import MailchimpWrapper from "./ui/MailchimpWrapper"
// import { useSiteMetadata } from "../hooks/useSiteMetadata"

const query = graphql`
  query {
    sanityHeader {
      siteName
      nav {
        ... on SanityInternalLink {
          _type
          label
          link {
            ... on SanityPageModulaire {
              _type
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`

const Header = () => {
  const {
    sanityHeader: { siteName, nav },
  } = useStaticQuery(query)

  const [navOpen, setNavOpen] = useState(false)
  const ref = useRef()
  const { location } = useWrapperContext()

  useEffect(() => {
    // ref.current.classList.remove("bg-secondary")

    const token = subscribe("BURGER", (e, d) => {
      setNavOpen(d)
    })

    return () => {
      unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (navOpen) {
      const mobileNavBounding = document
        .querySelector("#nav-mobile")
        .getBoundingClientRect()

      document.documentElement.style.setProperty(
        "--nav-mobile-height",
        mobileNavBounding.height + "px"
      )

      document.documentElement.classList.add("no-scroll")
      document.body.classList.add("is-mobile-nav-open")
    } else {
      document.documentElement.classList.remove("no-scroll")
      document.body.classList.remove("is-mobile-nav-open")
    }
  }, [navOpen])

  // const _onScroll = () => {
  //   if (window.pageYOffset > window.innerHeight) {
  //     ref.current.classList.add("bg-secondary")
  //   } else {
  //     ref.current.classList.remove("bg-secondary")
  //   }
  // }

  // const _searchToggle = () => {
  //   publish("SEARCH_TOGGLE")
  // }

  return (
    <header
      ref={ref}
      id="main-header"
      className={clsx(
        " z-50 w-full top-0 mix-blend-difference ",
        location.pathname === "/" ? "fixed" : "sticky"
        // navOpen ? "is-mobile-nav-open" : ""
      )}
    >
      <div
        className={clsx(
          "header-top py-8- px-sm md:px-md bb lh-30"
          // navOpen ? "bg-secondary text-primary" : ""
        )}
      >
        <div className="flex justify-between">
          <nav id="nav-primary">
            <ul className="flex">
              <li className="pr-smAlt">
                <div className="site-name">
                  <Link to="/">{siteName}</Link>
                </div>
              </li>
              {nav.map((li, i) => (
                <li key={i} className="pr-smAlt hidden-sm nav-link--page">
                  <Link to={_linkResolver(li.link)}>{li.label}</Link>
                </li>
              ))}
            </ul>
          </nav>
          <nav id="nav-secondary" className="hidden-sm">
            <ul className="flex">
              <li className="pl-smAlt">
                <BtnNewsletterToggle />
              </li>
              {/* <li className="pl-smAlt">
                <BtnSearchToggle />
              </li> */}
              <li className="pl-smAlt">
                <Cart />
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="hidden-sm">
        {/* <SearchForm /> */}
        <MailchimpWrapper />
      </div>
      <div className="sm-only">
        <nav
          id="nav-mobile"
          className={clsx(
            " z-50 px-sm- py-8- bg-primary text-secondary bb h-screen pt-header-height-",
            navOpen ? "block" : "hidden"
          )}
        >
          <ul className="flex flex-col justify-between h-full items-center">
            {nav.map((li, i) => (
              <li key={i} className=" bb">
                <Link to={_linkResolver(li.link)}>{li.label}</Link>
              </li>
            ))}
            <li className=" bb">
              <div className="w-full text-center">
                <BtnNewsletterToggle />
                <MailchimpWrapper />
              </div>
            </li>
            {/* <li className=" bb">
              <div className="w-full text-center">
                <BtnSearchToggle />
                <SearchForm />
              </div>
            </li> */}
            <li className=" bb">
              <Cart />
            </li>
          </ul>
        </nav>
      </div>
      <Burger />
    </header>
  )
}

export default Header
