import React, { useState } from "react"
import PropTypes from "prop-types"
import jsonp from "jsonp"
// import PropTypes from "prop-types";
// import styled, { css } from "styled-components"
import clsx from "clsx"
import { publish } from "pubsub-js"

// const InputOutline = styled.div`
//   flex-shrink: 0;
//   ${props =>
//     props.input &&
//     css`
//       min-width: calc(100% - 125px);
//       margin-right: var(--spacingSM);
//     `}
//   input,button {
//     width: 100%;
//     border: none;
//     appearance: none;
//     border: 1px solid;
//     padding: 0 0.2em;
//     height: var(--headerHeight);
//     line-height: calc(var(--headerHeight) - 3px);
//     min-width: 120px;
//   }
//   // button:hover {
//   //   background: black;
//   //   border-color: black;
//   //   color: white;
//   // }
//   // input {
//   //   width: 100%;
//   //   border: none;
//   // }
//   // button {
//   //   width: 120px;
//   //   background: green;
//   // }
// `

const Mailchimp = props => {
  const { messages } = Mailchimp.defaultProps

  const { fields, action, className } = props
  const [state, setState] = useState()
  const [status, setStatus] = useState()

  const getButtonMsg = () => {
    switch (status) {
      case "sending":
        return messages.sending
      case "success":
        return messages.success
      case "duplicate":
        return messages.duplicate
      case "empty":
        return messages.empty
      case "error":
        return messages.error
      default:
        return messages.button
    }
  }

  const handleSubmit = evt => {
    // alert("submit");
    evt.preventDefault()
    const values = fields
      .map(field => {
        return `${field.name}=${encodeURIComponent(state[field.name])}`
      })
      .join("&")
    const path = `${action}&${values}`
    const url = path.replace("/post?", "/post-json?")
    // const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
    // console.log(path, url);
    const email = state["EMAIL"]
    // console.log(email);
    // return;
    validateEmail(email) ? sendData(url) : setStatus("empty")
    // !regex.test(email) ? setStatus("empty") : sendData(url);
  }

  const validateEmail = email => {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const sendData = url => {
    setStatus("sending")
    jsonp(url, { param: "c" }, (err, data) => {
      // console.log(err);
      // console.log(data);
      if (data.msg.includes("already subscribed")) {
        setStatus("duplicate")
      } else if (err) {
        setStatus("error")
      } else if (data.result !== "success") {
        setStatus("error")
      } else {
        setStatus("success")
        setTimeout(() => {
          publish("NEWSLETTER_TOGGLE", false)
        }, 2000)
      }
    })
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={clsx(className, "mailchimp-form")}
      >
        <div className="flex justify-between w-full">
          <div className="inputs flex-2">
            {fields.map(input => (
              <div key={input.name} className="w-full input-wrapper">
                {/* <label htmlFor={input.name} className="sm-only">
                  {input.name}
                </label> */}
                <input
                  {...input}
                  role="textbox"
                  onChange={({ target }) =>
                    setState({ [input.name]: target.value })
                  }
                  className="w-full"
                  // defaultValue={state[input.name]}
                />
              </div>
            ))}
          </div>
          <div className="">
            <button
              disabled={status === "sending" || status === "success"}
              type="submit"
              aria-label="submit"
              className={"buttonClassName button"}
            >
              <span>{getButtonMsg()}</span>
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
export default Mailchimp

Mailchimp.defaultProps = {
  messages: {
    sending: "Envoi...",
    success: "Inscription validée. Merci!",
    error: "Erreur :(",
    empty: "E-mail vide.",
    duplicate: "Trop de tentatives d'inscription pour cette adresse e-mail",
    button: "S’abonner à la newsletter",
  },
  buttonClassName: "",
  // styles: {
  //   sendingMsg: {
  //     color: "#0652DD"
  //   },
  //   successMsg: {
  //     color: "#009432"
  //   },
  //   duplicateMsg: {
  //     color: "#EE5A24"
  //   },
  //   errorMsg: {
  //     color: "#ED4C67"
  //   }
  // }
}

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
}
