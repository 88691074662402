import React from "react"
import { motion } from "framer-motion"

const Splash = () => {
  return (
    <motion.div
      animate={{
        opacity: 0,
        transition: {
          delay: 0.5,
        },
        transitionEnd: {
          display: "none",
        },
      }}
    >
      <div
        id="splash"
        className={
          "fixed inset-0 bg-secondary flex justify-center items-center z-50"
        }
      >
        Books make friends
      </div>
    </motion.div>
  )
}

export default Splash
