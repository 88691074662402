import React from "react"
import { publish } from "pubsub-js"

const BtnNewsletterToggle = () => {
  const _toggle = () => {
    publish("NEWSLETTER_TOGGLE")
  }

  return (
    <div>
      <button
        id="newsletter-toggle"
        onClick={_toggle}
        className="p-sm md:p-0 w-full md:w-auto"
      >
        Newsletter
      </button>
    </div>
  )
}

export default BtnNewsletterToggle
