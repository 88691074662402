import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { subscribe, unsubscribe } from "pubsub-js"
import Mailchimp from "./Mailchimp"
import useDeviceDetect from "../../hooks/useDeviceDetect"

const MailchimpWrapper = () => {
  const [display, setDisplay] = useState(false)
  const { isMobile } = useDeviceDetect()
  useEffect(() => {
    const token = subscribe("NEWSLETTER_TOGGLE", () => {
      setDisplay(!display)
    })

    return () => unsubscribe(token)
  }, [display])

  return (
    <div
      className={clsx(
        "p-0 md:py-8 md:px-md bb w-full py-md- ",
        display ? "block" : "hidden"
      )}
    >
      <Mailchimp
        action="https://buildingparis.us5.list-manage.com/subscribe/post?u=8a8b23b6691303402d5307d91&amp;id=0c368c3d5e"
        // action="https://kevincouliau.us6.list-manage.com/subscribe/post?u=16e874bbbfba8e847e2d8ac96&id=c4949d1ece"
        fields={[
          {
            name: "EMAIL",
            // placeholder: isMobile ? "s’abonner..." : "E-mail",
            placeholder: "E-mail",
            type: "email",
            required: true,
          },
        ]}
      />
    </div>
  )
}

export default MailchimpWrapper
