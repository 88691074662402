// /* global Snipcart:false */
import React from "react"
import clsx from "clsx"
import useShop from "./ShopContext"

const Cart = () => {
  const { cart } = useShop()
  const hasItemsInCart = cart && cart.items.count > 0

  return (
    <div
      className={clsx(
        "cart md:uppercase transition-all"
        // hasItemsInCart
        //   ? "translate-x-0 opacity-1"
        //   : "translate-x-full opacity-0"
      )}
    >
      <button className="snipcart-checkout ">
        <span>Panier</span>
        {hasItemsInCart && <span> ({cart.items.count})</span>}
      </button>
    </div>
  )
}

export default Cart
