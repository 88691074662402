import React, { useEffect, useState } from "react"
import styled from "styled-components"
import clsx from "clsx"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  /* width: 22px;
  height: 22px; */
  /* background: red; */
  opacity: 0;
  /* transition: transform 0ms ease-out; */

  pointer-events: none;
  will-change: transform;
  mix-blend-mode: difference;
  .dot {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 100%;

    background: ${props => props.color};
    /* color: ${props => props.color}; */
    /* border: 1px solid; */
    transition: background-color 150ms ease, transform 150ms ease-out;
  }
  svg {
    opacity: 0;
  }
  &.is-anchor-or-button {
    .dot {
      /* background: transparent; */
      transform: scale(2.2);
    }
  }
  &.is-mousedown {
  }
  &.is-input {
    visibility: hidden;
  }

  display: none;
  @media (hover: hover) {
    display: block;
  }
  /* @media all and (max-width: 768px) { */
  @media not all and (hover: none) {
  }
`

//https://codepen.io/Starglider/pen/LYEELVy?editors=0010
const Cursor = ({ color, size }) => {
  // const inertia = 0.3;
  const [css, setCss] = useState({ x: 0, y: 0, opacity: 0, rotate: 0 })
  const [isAnchorOrButton, setIsAnchorOrButton] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isInput, setIsInput] = useState(false)

  useEffect(() => {
    const _onMouseMove = e => {
      const __isAnchorOrButton = _getIsAnchorOrButton(e.target)
      setIsAnchorOrButton(__isAnchorOrButton)

      const __isInput = _getIsInput(e.target)
      setIsInput(__isInput)

      let offset = size / 2

      setCss(css => ({
        ...css,
        x: e.clientX - offset,
        y: e.clientY - offset,
        opacity: 1,
      }))
    }

    document.addEventListener("mousemove", _onMouseMove)
    document.addEventListener("mousedown", _onMouseDown)
    document.addEventListener("mouseup", _onMouseUp)

    return () => {
      document.removeEventListener("mousemove", _onMouseMove)
      document.removeEventListener("mousedown", _onMouseDown)
      document.removeEventListener("mouseup", _onMouseUp)
    }
  }, [size])

  // const _update = e => {}

  const _onMouseDown = () => setIsMouseDown(true)
  const _onMouseUp = () => setIsMouseDown(false)

  const _getIsAnchorOrButton = target => {
    // console.log(target.classList.contains("button"));
    // console.log(target.role)
    // alert("here")
    return (
      target.tagName.toLowerCase() === "a" ||
      target.tagName.toLowerCase() === "button" ||
      target.classList.contains("button") ||
      target.classList.contains("pointer") ||
      target.classList.contains("cursor-pointer") ||
      target.role === "button"
    )
  }
  const _getIsInput = target => target.role === "textbox"
  // console.log(css)
  return (
    <Wrapper
      color={color}
      size={size}
      className={clsx(
        "cursor",
        isAnchorOrButton ? "is-anchor-or-button" : "",
        isMouseDown ? "is-mousedown" : "",
        isInput ? "is-input" : ""
      )}
      style={{
        transform: `translate(${css.x}px, ${css.y}px)`,
        opacity: css.opacity,
      }}
    >
      <div className="dot"></div>
    </Wrapper>
  )
}

export default Cursor
