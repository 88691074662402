import React from "react"
import PortableText from "@sanity/block-content-to-react"
// import SanityImage from "./SanityImage";
// import VideoEmbed from "./ui/VideoEmbed";

const SanityTexte = ({ input }) => {
  // console.log(input)
  const serializers = {
    types: {
      block(props) {
        // console.log(props);
        switch (props.node.style) {
          case "h2":
            return <h2>{props.children}</h2>
          // case "text_lg":
          //   return <p className='text-lg'>{props.children}</p>;
          default:
            return <p>{props.children}</p>
        }
      },
      // image: ({ node }) => {
      //   return <SanityImage input={node} />;
      // },
      // videoEmbed: ({ node }) => {
      //   return <VideoEmbed input={node.url} />;
      // },
    },

    marks: {
      align_left: ({ children }) => (
        <span className="text-left">{children}</span>
      ),
      align_center: ({ children }) => (
        <span className="text-center">{children}</span>
      ),
      sup: ({ children }) => <sup>{children}</sup>,

      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        // console.log(mark);
        const { href } = mark
        return (
          <a href={href} target="_blank" rel="noopener, noreferrer">
            {children}
          </a>
        )
      },
    },
  }
  // console.log(input)
  return (
    <div className="texte">
      <PortableText blocks={input} serializers={serializers} />
    </div>
  )
}

export default SanityTexte
